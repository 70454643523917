.youtube-section {
    display: grid;
    /* Decrease the size by specifying a max size for each column, adjust the '1fr' to a fixed size if needed */
    grid-template-columns: repeat(3, minmax(0, 300px)); /* This assumes a max of 300px per column */
    /* Increase the grid gap */
    grid-gap: 40px; /* Adjust the spacing between items as needed */
    padding: 20px;
    justify-content: center; /* This centers the columns in the grid when they don't take up the full width */
    margin: auto;
  }
  
  .youtube-item {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    font-size: 14px; /* Set the font-size to your desired size */
    margin-top: 20px; /* Adjust the space between the image and the text as needed */
    text-align: center; /* Center the text below the image */
    line-height: 1.2; /* Adjust line height for better readability if needed */
    word-wrap: break-word; /* Prevents long words from overflowing */
  }
  
  .youtube-thumbnail-container {
    position: relative;
    /* Keep the padding-top consistent to maintain the aspect ratio */
    padding-top: 56.25%; /* for 16:9 aspect ratio, adjust if different */
    width: 100%; /* Make sure the width is not restricted here */
    overflow: hidden;
  }
  
  .youtube-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit:inherit;
    }
  
  /* Continue with your responsive adjustments */
  @media (max-width: 1024px) {
    .youtube-section {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .youtube-section {
      grid-template-columns: 1fr;
    }
  }
  .youtube-thumbnail:hover {
    transform: scale(1.05); /* Slightly increase size on hover */
  }
  
