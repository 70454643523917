/* Container for the entire Home section */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-attachment: fixed;
  position: relative;
  padding: 30px;
  background-attachment: fixed;
  min-height: 100vh; /* Ensure it's at least the height of the viewport */
  min-height: calc(100vh - 10px); /* Adjust 120px to the height of your footer */
  padding-bottom: 0px; /* Same as the height of your footer */
}

.text-container p, .text-container h1, .text-container h2 {
  transition: color 0.3s ease-in-out;
}

.text-container p:hover, .text-container h1:hover, .text-container h2:hover {
  color: #007bff; /* Change color on hover */
}
/* Shared styling for image and text containers */
.image-and-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.image-container {
  width: 100%;
  max-width: 400px; /* Adjust based on your design */
}

.home-image {
  width: 100%;
  height: auto; /* Adjust for your image's aspect ratio */
  border-radius: 8px;
}

.text-container {
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
}

.text-container p, .text-container h2 {
  font-size: 16px;
  line-height: 1.5;
}

/* Responsiveness */
@media (min-width: 768px) {
  .image-and-text-container1 {
    display: flex;
    flex-direction: row; /* Ensure they are side by side */
    align-items: center; /* Align items vertically in the center */
    justify-content: space-between; /* Creates space between the image and text */
    width: 85%;
  }
  .image-and-text-container2 {
    display: flex;
    flex-direction: row; /* Ensure they are side by side */
    align-items: center; /* Align items vertically in the center */
    justify-content: space-between; /* Creates space between the image and text */
    width: 85%;
  }
  .image-container1 {
    flex-basis: 45%; /* Allocates 40% of the container's width to the image */
    padding-right: 20px; /* Optional: Adds some padding to the right of the image */
    margin: 40px;
  }
  .image-container2 {
    flex-basis: 30%; /* Allocates 40% of the container's width to the image */
    padding-right: 20px; /* Optional: Adds some padding to the right of the image */
    margin: 45px;
  }

  .text-container1 {
  flex-basis: 65%; /* Allocates 60% of the container's width to the text */
  text-align:inherit; /* Aligns the text to the left */
  padding-left: 20px; /* Optional: Adds some padding to the left of the text */
  margin: 20px;
  
  
}
.text-container2 {
  flex-basis: 60%; /* Allocates 60% of the container's width to the text */
  text-align: left; /* Aligns the text to the right */
  padding-left: 50px; /* Optional: Adds some padding to the left of the text */
  a {
    color:rgb(33, 7, 80); /* Specify your desired color */
  }
}
}

/* Carousel Styles */
.carousel-container {
  width: 80%; /* Full width of the container */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  position: relative; /* To position the navigation buttons */
  height: 650px; /* Set your desired height */
  overflow: hidden; /* Hide the overflowed parts of images */
  border-radius: 10px;
  box-sizing: border-box; 
}
.carousel-container:hover .carousel-image {
  transform: scale(1.05); /* Slightly enlarge images on hover */
}
.carousel {
  display: flex; /* Flexbox for internal alignment */
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  overflow: hidden; /* Hide the overflow */
  width: 100%; /* Adjust the width of the carousel to fit the container */
  margin: auto; /* Center the carousel horizontally */
}

.carousel-image {
  max-width: 100%; /* Image should not exceed the width of its container */
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.5s ease-in-out;
}

/* Adjust button styles */
button {
  background-color: rgba(68, 68, 68, 0.5);
  border: none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  transition: background-color 0.3s ease;
}

/* Position buttons on either side of the carousel */
button:first-of-type {
  left: 0;
}

button:last-of-type {
  right: 0;
}

/* Ensure the buttons are not overlapping the image */
button:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: scale(1.1); /* Enlarge buttons on hover */
}

/* Add responsiveness */
@media (max-width: 768px) {
  .carousel {
    width: 100%; /* Make the carousel take up the full width on smaller screens */
  }
}

html {
  scroll-behavior: smooth;
}
.contact-section {
  margin-bottom: 0;
}

ul {
  margin-top: 0px;
  margin-bottom: 0;
  padding-inline-start: 20px; /* Adjust the indentation of the bullet points */
}

li {
  margin-bottom: 0px; /* Adjust this value to reduce or increase the gap */
  margin-top: 2px;
  font-size: 17px;
  font-weight: 550;
}

.contact-section {
  margin-bottom: 0;
}
