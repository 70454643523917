/* Container for the entire pub section */
.pub-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
  }
  
  /* Container for the image and text */
  .image-and-text-pub-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  /* Container for the image */
  .image-pub-container {
    width: 100%;
    max-width: 200px; /* Adjust the maximum width as needed*/
    height: 10%;
  }
  
  /* Style for the image */
  .pub-image {
    width: 100%; /* Optional: Add border-radius for rounded corners */
  }
  
  /* Container for the text */
  .text-pub-container {
    width: 100%;
    max-width: 600px; /* Adjust the maximum width as needed */
    margin: 20px;
  }
  
  /* Style for the text paragraph */
  .text-pub-container p {
    font-size: 16px;
    /* line-height: 1.5; */
  }
  
  /* Media query for responsiveness */
  @media (min-width: 768px) {
    .image-and-text-pub-container {
      flex-direction: row; /* Make image and text side by side */
      justify-content: center; /* Center them */
      align-items: center; /* Align items vertically */
    }
  
    .image-pub-container{
      width: 50%; 
      position: center;
      margin: 0 auto;
    }
    .text-pub-container {
      width: 50%; /* Adjust so they take up half of the container each */
    }
  
    /* Optional: Adjust margins/padding as necessary for spacing */
  }
  
  .publi-section {
    margin-top: 20px;
    width: 70%;
  }
  .publi-section h2 {
    max-height: 4vh;
    min-height: 4vh;
  }
  
  .publi-content {
    padding: 20px;
  }
  .publi-content p {
    font-size: 20px;
    /* min-height: 7vh; */
  }
  .publi-content h3 {
    font-size: 20px;
    /* min-height: 4vh; */
  }
  
  
  /* Responsive CSS */
  @media only screen and (max-width: 600px) {
    .pub-container {
      flex-direction: column;
    }
    
    .text-pub-container, .image-pub-container {
      width: 100%;
      text-align: center;
    }
  }
  
