

.research-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    flex: 1;
    margin-right: 20px;
  }
  
  .research-header {
    font-size: 10;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .research-projects {
    width: 100%;
    max-width: 800px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-left: 9%;
    margin-top: 1%;
  }
  
  .research-project {
    background: #cdd4d8;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    transition: transform 0.5 ease, box-shadow 0.s ease;
  }
  
  .research-project:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  }
  
  .project-title {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .project-description {
    font-size: 1rem;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .research-header {
      font-size: 2rem;
    }
  
    .research-projects {
      grid-template-columns: 1fr;
    }
  
    .research-project {
      margin-bottom: 20px;
    }
    
  }

  .research-page {
    display: flex;
    justify-content: space-between; /* Ensures spacing between elements */
    align-items: flex-start; /* Aligns items at the top */
    
  }

  
  
 

  .project-information {
    height:390px; 
    width: 380px;
    position:sticky; /* Keeps the div in place while scrolling */
    top: 0; /* Aligns the top of the div with the top of the viewport */
    right: 0px; /* Aligns the div on the right side of the viewport */
    background: #dae2e7;
    padding: 20px;
    border-radius: 10px;
    overflow-y: auto; /* Adds scroll inside the div if content is too long */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    z-index: 100; /* Ensures it does not get hidden behind other elements */
    margin-right: 10%;
    margin-top: 8%;
    margin-bottom: 12%;
  }

  